import { Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
const Index = lazy(() => import('../pages/index'))
const Login = lazy(() => import('../pages/login/Login.jsx'))
const Home = lazy(() => import('../pages/index/components/Home/Home.jsx'))
const DownLoad = lazy(() => import('../pages/index/components/DownLoad/DownLoad.jsx'))
const Buy = lazy(() => import('../pages/index/components/Buy/Buy.jsx'))
const Documentation = lazy(() => import('../pages/index/components/Documentation/Documentation.jsx'))
const User = lazy(() => import('../pages/index/components/User/User.jsx'))
const ChangePasword = lazy(() => import('../pages/changePassword/ChangePassword.jsx'))
const VerifyForgetPassword = lazy(() => import('../pages/verifyForgetPassword/verifyForgetPassword.jsx'))
const InterviewCoach = lazy(() => import('../pages/interviewCoach/interviewCoach.jsx'))
const Admin = lazy(() => import('../pages/admin/Admin.jsx'))
const Coach = lazy(() => import('../pages/index/components/Coach/Coach.jsx'))
const SimulatedInterview = lazy(() => import('../pages/index/components/SimulatedInterview/index.jsx'))
const Detil = lazy(() => import('../pages/index/components/SimulatedInterview/components/Detil/Detil.jsx'))
const Interview = lazy(() => import('../pages/index/components/SimulatedInterview/components/Interview/Interview.jsx'))
const InterviewIndex = lazy(() => import('../pages/index/components/SimulatedInterview/components/Index/Index.jsx'))
const More = lazy(() => import('../pages/index/components/SimulatedInterview/components/More/More.jsx'))
const LoadingCom= (comp) => (
    <Suspense fallback={<div></div>}>
        {comp}
    </Suspense>
)
export default [
    {
        path:"/",
        element: LoadingCom(<Index />),
        children: [
            {
                path: "home",
                element: LoadingCom(<Home />)
            },
            {
                path: "downLoad",
                element: LoadingCom(<DownLoad />)
            },
            {
                path: "buy",
                element: LoadingCom(<Buy />)
            },
            {
                path: "documentation",
                element: LoadingCom(<Documentation />)
            },
            {
                path: "user",
                element: LoadingCom(<User />)
            },
            {
                path: "coach",
                element: LoadingCom(<Coach />)
            },
            {
                path: "interviewCoach", 
                element: LoadingCom(<InterviewCoach />)
            },
            {
                path: "simulatedInterview", 
                element: LoadingCom(<SimulatedInterview />),
                children: [
                    {
                        path: 'InterviewIndex',
                        element: LoadingCom(<InterviewIndex />)
                    },
                    {
                        path: "detil",
                        element: LoadingCom(<Detil />)
                    },
                    {
                        path: "interview",
                        element: LoadingCom(<Interview />)
                    },
                    {
                        path: "more",
                        element: LoadingCom(<More />)
                    },
                    {
                        index: true,
                        element: <Navigate to='InterviewIndex' />
                    },
                ]
            },
            {
                index: true,
                element: <Navigate to='home' />
            },
        ]
    },
    {
        path:"/login",
        element: LoadingCom(<Login />)
    },
    {
        path:"/changepasword",
        element: LoadingCom(<ChangePasword />)
    },
    {
        path: "/user",
        element: LoadingCom(<User />)
    },
    {
        path: "/verify_forget_password",
        element: LoadingCom(<VerifyForgetPassword />)

    },
    {
        path: "/admin",
        element: LoadingCom(<Admin />)
    }
]