import router from './routers';
import { useRoutes } from 'react-router-dom'
import './App.css';
function App() {
  const routers = useRoutes(router)
  return (
    <div className="App">
    {routers}
    </div>
  );
}

export default App;
